import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../constent/theme";
import video from '../assets/video/video3.mp4'
import poster2 from '../assets/images/poster2.png'

const Home2OurBlog = () => {
  const navigate = useNavigate();
  return (
    <section className="content-inner overflow-hidden"  style={{marginTop:'120px'}}>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            {/* <div className="section-head">
               <h2 className="title wow flipInX">Our Restaurant</h2>
            </div> */}
            <div className="testimonial-2">
            <div className="testimonial-detail">
              {/* <div className="testimonial-text wow fadeInUp">
                <p>
                Welcome to Big Panda, Commerce City, CO's premier destination for delicious Chinese and Asian cuisine. While we specialize in a wide array of flavorful Chinese dishes, our diverse menu also features other Asian-inspired offerings like Thai noodles and vegan options, ensuring there's something for everyone. Whether you're craving savory stir-fries, hearty fried rice, or spicy Thai curries, we have it all. Conveniently order online through our website and take advantage of our reliable delivery service, bringing the best of Chinese cuisine right to your doorstep. Experience the finest flavors of the East at Big Panda today!
                  </p>
              </div> */}
<h5 className="text-center text-red caveat-title mt-3">"Always Fresh, Always Delicious"</h5>
{/* <span className="testimonial-position">BigPanda</span> */}
<div className="text-center mt-5 mb-3">
  <div
      onClick={() => navigate('/order')}
      className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1 w-50"
  >
      <span>Order Online</span>
  </div>
</div>
               
            </div>
          </div>
            
          </div>
          {/* <div className="col-xl-12 col-lg-12 m-b30 wow fadeInUp">
            <div
              className="dz-card style-3 dz-card-large"
              style={{ backgroundImage: `url(${IMAGES.blog_large_pic1})` }}
            >
              <video autoPlay loop muted playsInline poster={poster2}>
                <source src={video} type="video/mp4"  />
              </video>
              <div className="dz-info">
                <h3 className="dz-title">
                  <Link to="/" className="text-white">
                    Exploring the Chiense Cuisine
                  </Link>
                </h3>
                <div className="dz-meta">
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Home2OurBlog;
