import { IMAGES } from "../constent/theme"
import { Link } from "react-router-dom"
import emailjs from "@emailjs/browser"
import toast, { Toaster } from "react-hot-toast"
import { useRef, useState } from "react"

const Footer2 = () => {
  const heartRef = useRef(null)
  const [input, setInput] = useState("")
  const form = useRef(null)
  const sendEmail = e => {
    e.preventDefault()
    setInput("")
    if (form.current) {
      emailjs
        .sendForm(
          "emailId",
          "template_0byuv32",
          form.current,
          "qUDIPykc776NYHv4m"
        )
        .then(
          () => {
            toast.success("Successfully send!")
          },
          error => {
            toast.error(error.text)
          }
        )
    }
  }
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      <footer className="site-footer style-2 mt-5" id="footer">
        <div className="footer-bg-wrapper" id="app-banner">
          <div className="footer-top">
            <div className="container">
            
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 wow fadeInUp">
                  <div className="widget widget_getintuch">
                    <h5 className="footer-title text-black">Contact</h5>
                    <ul>
                      <li>
                        <i className="flaticon-placeholder"></i>
                        <a className="text-black" href="https://www.google.com/maps/place/Big+Panda/@39.8840026,-104.8089595,17z/data=!3m1!4b1!4m6!3m5!1s0x876c6e531783d31d:0x960b41edf21dd1e6!8m2!3d39.8839985!4d-104.8063846!16s%2Fg%2F1tdbrc2m?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                        1078 Ogden St	Denver, CO 80218
                        </a>
                      </li>
                      <li>
                        <i className="flaticon-telephone"></i>
                        <a href="tel:+13038318277" className="text-black">
                        (303)-831-8277
                        
                        </a>
                      </li>
                      <li>
                      <p className="text-black">
            Monday - Sunday: 3PM - 10PM
            <br />
            Closed Tuesdays
      </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title text-black  ">Our Links</h5>
                    <ul>
                      <li>
                        <Link to="/">
                          <span className="text-black">Home</span>
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/about-us">
                          <span className="text-black">About Us</span>
                        </Link>
                      </li> */}
                      
                      <li>
                        <Link to="/online-order">
                          <span className="text-black">Online Order</span>
                        </Link>
                      </li>
                 
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title text-black">OUR SERVICES</h5>
                    <ul>
                  
                      <li>
                        <Link to="/">
                          <span className="text-black">Top Chefs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">
                          <span className="text-black">Services</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <span  className="text-black">Pickup In Store</span>
                        </Link>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="row">
                <span className="copyright-text">
                  Crafted With{" "}
                  <span
                    className="heart"
                    ref={heartRef}
                    onClick={() => {
                      heartRef.current?.classList.toggle("heart-blast")
                    }}
                  ></span>{" "}
                  by{" "}
                  <Link to="/">
                    Ownmenu
                  </Link>
                </span>
              </div>
              
            </div>
        </div>
      </footer>
    </>
  )
}

export default Footer2
