import React, { useState, useEffect } from "react";
import MainBanner2 from "../components/MainBanner2";
import Header from "../components/Header";
import Footer2 from "../components/Footer2";
import Home2OurBlog from "../components/Home2OurBlog";
import Gallery from "../components/Gallery";
import FamilyDinner from "../components/FamilyDinner";
import { Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Home2 = ({ restaurant }) => {
  // const [show, setShow] = useState(false);
  const navigate = useNavigate()



  return (
    <div className="page-content bg-white ">
      
      <Header />
      {/* <MainBanner2 /> */}
      <Home2OurBlog />
      <Gallery />
      {/* <FamilyDinner /> */}
      <Footer2 />

 {/* Modal */}
 {/* <Modal show={show} onHide={handleClose} centered size="sm">
        <Modal.Body className="p-0" style={{ position: "relative" }}>
          <img
            src="https://static.wixstatic.com/media/2b1bd3_0b4a19c16988405dbc4cbc2bacbd6334~mv2.jpg/v1/fill/w_535,h_339,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/2b1bd3_0b4a19c16988405dbc4cbc2bacbd6334~mv2.jpg"
            alt="Order Now"
            className="img-fluid w-100"
          />
          <FontAwesomeIcon
            icon={faClose}
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              fontSize: "30px",
              color: "#fff",
            }}
            />

          <Button
            variant="primary"
            onClick={() => navigate('/order')}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: '15px 25px'
            }}
          >
            Order Now
          </Button>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Home2;
