import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import w1 from './w1.JPG'
import w2 from './w2.JPG'
import w3 from './w3.JPG'
import w4 from './w4.JPG'
import w5 from './w5.JPG'
const Gallery = () => {
  const images = [
    {img: w1},
    {img: w2},
    {img: w3},
    {img: w4},
    {img: w5}
  
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
        
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="gallery-container container ">
      {/* <hr /> */}

      {/* <div className="thirteen mb-2">
        <h1 className='text-red'>Dine In | Take Out | Delivery</h1>
      </div> */}
      <div className="container">
      <Slider {...settings}>
  {images.map((img, ind) => (
    <div key={ind} className="dz-team style-1 wow fadeInUp gallery-slide">
      <div className="dz-media">
        <img
          src={img.img}
          alt={`Image ${ind + 1}`}
          className="gallery-image img-fluid"
        />
      </div>
    </div>
  ))}
</Slider>

      </div>
    </div>
  );
};

export default Gallery;
